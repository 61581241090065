import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { find, get } from 'lodash';
import { CredentialsService } from '../services';
import { PermissionService } from '../services/permission.service';
export type permissionTypes = 'canCreate' | 'canView' | 'canUpdate' | 'canDelete' | 'isAdmin' | 'isSuperAdmin';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  @Input() public resource!: string | string[];
  @Input() public enableActions!: permissionTypes;
  @Input() public isPublicView: boolean = false;

  public userData: any = this.credentialsService.getLoggedUser();
  public rights: any = this.credentialsService.getCreds();
  public removeElement: boolean = true;
  constructor(
    private el: ElementRef,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    if (this.isPublicView || this.hasPermissionForResource()) {
      return;
    }

    this.removeElementFromDom();
  }

  public removeElementFromDom(): void {
    if (this.removeElement) {
      this.el.nativeElement.remove();
    }
  }

  private hasPermissionForResource(): boolean {
    if (Array.isArray(this.resource)) {
      return this.resource.some((r) => this.hasPermission(r, this.enableActions));
    } else {
      return this.hasPermission(this.resource as string, this.enableActions);
    }
  }

  private hasPermission(resourceName: string, verifyPermission: permissionTypes): boolean {
    return this.permissionService.handleAccess(resourceName, verifyPermission, this.isPublicView);
  }
}
