import { Injectable } from '@angular/core';
import {lastValueFrom} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import {IGeocoderResult} from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {
    constructor(private readonly _baseHttpService: BaseHttpService) {}

    public searchStreets(search: String, size: number = 6): Promise<IGeocoderResult[]> {
        return lastValueFrom(this._baseHttpService.post('geocoder/streets',{ search, size }));
    }

    async findCoordinates(id: number, table: string) {
        return lastValueFrom(this._baseHttpService.get(`geocoder/coordinates/${id}/${table}`));
    }
}
