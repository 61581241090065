<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
      <div class="text-white text-left flex justify-content-center align-items-center">
          <img class='hidden md:block' src="assets/img/logo-reverse1.png" alt="logo">
          <img class='ml-2 block md:hidden' src="assets/img/logo.png" alt="logo" style='height: 50px'>
      </div>
    </a>

    <div class='flex justify-content-between flex-grow-1 align-items-center'>
        <div>
            <button #menubutton class="p-link layout-menu-button layout-topbar-button"  (click)="layoutService.onMenuToggle()">
                <i class="pi pi-bars"></i>
            </button>



            <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
                <i class="pi pi-ellipsis-v"></i>
            </button>
        </div>
        <div>
            <img class='hidden md:block' src="assets/img/logos/logo-infospie-04.png" alt="INFO-SPIE" style="max-height: 120px;">
        </div>

        <div #topbarmenu class="rounded layout-topbar-menu hover:bg-blue-900 hover:text-white p-1" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
            <button class="p-link p-1" (click)="menu.toggle($event)" style="min-width: 150px;">
                <div class="m-0 w-full flex gap-2 justify-content-end align-items-center layout-topbar-button">
                    <div class="layout-topbar-button m-0">
                        <i class="pi pi-user"></i>
                        <span>Profile</span>
                    </div>
                    <div>
                        <label>{{getCurrentUserName()}}</label>
                        <p class="text-sm">{{getCurrentRole}}</p>
                    </div>
                </div>
            </button>

            <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items"></p-menu>
        </div>
    </div>
</div>
