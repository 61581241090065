import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminTemplateComponent } from './admin-template/admin-template.component';
import { FooterTemplateComponent } from './footer-template/footer-template.component';
import { SideBarTemplateComponent } from './side-bar-template/side-bar-template.component';
import { TopBarTemplateComponent } from './top-bar-template/top-bar-template.component';
import { MenuTemplateComponent } from './menu-template/menu-template.component';
import { MenuItemComponent } from './menu-template/menu-item/menu-item.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { AppConfigModule } from '../shared/components/config/config.module';
import { MenuModule } from 'primeng/menu';
import { DirectiveModule } from '../shared/directives/directive.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EmitFunctionsService } from '../shared/services';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ChangePasswordService } from './change-password-modal/change-password.service';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { PasswordModule } from 'primeng/password';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    AdminTemplateComponent,
    FooterTemplateComponent,
    SideBarTemplateComponent,
    TopBarTemplateComponent,
    MenuTemplateComponent,
    MenuItemComponent,
    ChangePasswordModalComponent,
  ],
  providers: [EmitFunctionsService, ChangePasswordService, DialogService, DynamicDialogRef, DynamicDialogConfig],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    MenuModule,
    DirectiveModule,
    ProgressSpinnerModule,
    ButtonModule,
    SkeletonModule,
    PasswordModule,
    DynamicDialogModule,
  ],
  exports: [AdminTemplateComponent],
  entryComponents: [ChangePasswordModalComponent],
})
export class TemplatesModule {}
