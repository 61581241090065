import { BASE_CONF } from "./base";
export const environment = {
  ...BASE_CONF,
  production: true,
  host: 'https://infocapa.planificacion.gob.bo/api',
  captchaKey: '6LeedT0nAAAAAO_jPi_nBueD6JTLPg-GsSiKPM_E',
  captchaEnabled: false,
  handsontableKey: 'non-commercial-and-evaluation',
  urlGeoserver: 'https://geoserver.planificacion.gob.bo/geoserver/',
  workspaceGeoserver: 'infocapa',
  googleTagManagerId: 'G-R2XS1NBVPN'
};

