import {Injectable} from '@angular/core';
import {get} from 'lodash-es';
import {CredentialsService} from '.';
import {InformationResource} from "../../modules/information-resource/class/information-resource";
import {coreFunctions} from "../utils/utils";
import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly permissionService: PermissionService,
    ) {}


    public user() {
      return this.credentialsService.getLoggedUser();
    }

    public entity(): InformationResource | null {
        return get(this.user(), 'entidades', null);
    }

    public isSuperAdminForResource(resource: string): boolean {
        const userPermission: any = get(this.user(), `accessTo.${resource}`, null);
        if (!userPermission) return false;

        return this.permissionService.isSuperAdmin(userPermission.permiso);
    }

    get defaultSectorId(): number | undefined {
        const entity: InformationResource | null = this.entity();
        if(!(entity && entity.informationResourceSector?.length)) return undefined;

        return entity.informationResourceSector[0].idSector;
    }

    get currentEntityId(): number | undefined {
        const entity: InformationResource | null = this.entity();
        return entity?.id;
    }

}
