import { Injectable } from '@angular/core';
import {lastValueFrom} from "rxjs";
import {BaseService} from "./base.service";
import {IUserRequest, IUserRequestVerification} from "../interfaces";
import {BaseHttpService} from "./base-http.service";
import {HttpOptions} from "../types";
import {InformationResource} from "../../modules/information-resource/class/information-resource";
import {Role} from "../../modules/role/interfaces";
import {environment} from "../../../environments/environment";
import {compact} from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class UserRequestService extends BaseService<IUserRequest> {
    constructor(httpService: BaseHttpService) {
        super(httpService, 'user-request');
    }

    async getByToken(token: string): Promise<IUserRequest> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/token/${token}`));
    }

    async upsertVerification(data: IUserRequestVerification, id: number): Promise<IUserRequest> {
        return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${id}/verification`, data));
    }

    async updatePartialData(data: IUserRequest, updateData: Partial<IUserRequest>, id: number) {
        const storeData: any = {
            token: data.token,
            ...updateData,
        };

        return this.update(storeData, id);
    }

    async createRequest(): Promise<IUserRequest> {
        return lastValueFrom(this._baseHttpService.post(this.namespace, {}));
    }

    async getAllEntities(token: string, options?: HttpOptions): Promise<InformationResource[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/token/${token}/entities`, options));
    }

    async getCities(token: string, options?: HttpOptions): Promise<InformationResource[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/token/${token}/cities`, options));
    }

    async getRoles(token: string, options?: HttpOptions): Promise<Role[]> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/token/${token}/roles`, options));
    }

    getPDFUrl(token: string, download = false) {
        const apiUrl = environment.host;
        const url = encodeURI(compact([apiUrl, this.namespace, 'token', token, 'pdf']).join('/'));
        if (download) return `${url}?download=${download}`;
        return url;
    }

    async sendNotifications(token: string): Promise<IUserRequest> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/token/${token}/notifications`, {}));
    }

}
