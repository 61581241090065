export const RequestStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    VERIFIED: 'VERIFIED',
    COMPLETED: 'COMPLETED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
});

export const UserRequestVerificationKeys = Object.freeze([
    {
        key: 'REQUEST_ENTITY',
        label: 'Entidad Solicitante',
        value: false,
        fields: ['entity'],
        optional: false,
        observations: '',
        fixed: false,
    },
    {
        key: 'SEO_IDENTITY',
        label: 'Identidad de la Autoridad Solicitante',
        value: false,
        fields: ['seoName', 'seoIDFile', 'seoCellPhone', 'seoPhone', 'seoJobtitle', 'seoEmail'],
        optional: false,
        observations: '',
        fixed: false,
    },
    {
        key: 'USER_IDENTITY',
        label: 'Identidad del Funcionario',
        value: false,
        fields: ['userFirstName', 'userFullName', 'userFatherLastName', 'userMotherLastname', 'userIDFile', 'userMemoFile', 'userCellPhone', 'userPhone', 'userEmail', 'userRole'],
        optional: false,
        observations: '',
        fixed: false,
    },
    {
        key: 'USER_JOB_TITLE',
        label: 'Cargo del Funcionario',
        value: false,
        fields: ['userJobtitle', 'userMemoFile'],
        optional: false,
        observations: '',
        fixed: false,
    },
    {
        key: 'SIGNED_FILES_DATA',
        label: 'Formulario de Solicitud con las firmas correspondientes',
        value: false,
        fields: ['requestSignedFile'],
        optional: false,
        observations: '',
        fixed: false,
    }
]);

