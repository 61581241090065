import { BaseHttpService } from './base-http.service';
import { lastValueFrom } from 'rxjs';
import { HttpOptions } from '../types';

export abstract class BaseService<T> {
  protected _baseHttpService: BaseHttpService;
  protected namespace: string;
  protected constructor(_baseHttpService: BaseHttpService, namespace: string) {
    this._baseHttpService = _baseHttpService;
    this.namespace = namespace;
  }

  async getAll(options?: HttpOptions): Promise<T[]> {
    return lastValueFrom(this._baseHttpService.get(this.namespace, options));
  }

  async getAllPublic(options?: HttpOptions): Promise<T[]> {
    return lastValueFrom(this._baseHttpService.get(`${this.namespace}/public`, options));
  }

  async store(data: T): Promise<T> {
    return lastValueFrom(this._baseHttpService.post(this.namespace, data));
  }

  async get(id: number | string, options?: HttpOptions): Promise<T> {
    return lastValueFrom(this._baseHttpService.get(`${this.namespace}/${id}`, options));
  }

  async getByCode(code: string): Promise<T> {
    return lastValueFrom(this._baseHttpService.get(`${this.namespace}/code/${code}`));
  }

  async update(data: T, id: number): Promise<T> {
    return lastValueFrom(this._baseHttpService.put(`${this.namespace}/${id}`, data));
  }

  async delete(id: number): Promise<boolean> {
    return lastValueFrom(this._baseHttpService.delete(`${this.namespace}/${id}`));
  }
}
